<template>
	<div class="DatePicker"
		:tabindex="datePickerInput ? '-1' : tabindex"
		:class="{
			allowInput: datePickerInput,
			readonly: settings.readonly,
			unitYear: settings.unit == 'year',
		}"
		@keyup.enter="toggle"
		@keyup.esc.stop="toggle">
		<DatePickerInput ref="input"
			:value="datePickerInput && hasFocus ? inputValue : displayValue"
			:disabled="settings.readonly || datePickerInput == false"
			:options="datePickerSettings"
			:placeholder="settings.placeholder"
			:class="inputClasses"
			:tabindex="datePickerInput ? tabindex : '-1'"
			:style="style"
			@click="onInputClick"
			@input="onInput"
			@focus="onFocus"
			@enter="onInputEnter"
			@blur="onBlur">
			<Icon v-if="settings.showDateIcon"
				class="iconCalendar"
				slot="before"
				:icon="$static.iconCalendar" />
			<Icon v-if="settings.clearable && (open || date)"
				class="clearIcon"
				slot="after"
				:icon="$static.iconClose"
				@click.stop="onClearClick" />
		</DatePickerInput>
		<div class="anchor"
			ref="anchorEl">
			<Popover v-if="open"
				:anchorEl="anchorEl"
				:refEl="refEl"
				:align="settings.align"
				:orientation="settings.orientation"
				:offset="popoverOffset"
				:outSideClickEl="$el"
				@outsideClick="outsideClick">
				<div v-if="pickerType == 'day'"
					class="picker day">
					<div class="pager">
						<div class="btn prev"
							@click.stop="onPaging('-month')">
							<Icon :icon="$static.iconPrev" />
						</div>
						<div class="text">
							<div class="month"
								@click.stop="pickerType = 'month'">{{ this.pagerMonth }}</div>
							<div class="year"
								@click.stop="pickerType = 'year'">{{ this.pagerYear }}</div>
						</div>
						<div class="btn next"
							@click.stop="onPaging('+month')">
							<Icon :icon="$static.iconNext" />
						</div>
					</div>
					<div class="dayGrid">
						<div class="heading"
							v-for="item in calendarHeading"
							:key="`heading${item.text}`"
							:style="item.style">
							{{ item.text }}
						</div>
						<div class="day"
							v-for="day in calendarDays"
							:key="day.dayNo"
							:style="day.style"
							:class="day.class"
							@click.stop="onDayClick(day)">
							{{ day.text }}
						</div>
						<div class="week"
							v-for="kw in calendarWeeks"
							:key="`kw${kw.text}`"
							:style="kw.style"
							:class="{ thisWeek: kw.thisWeek }">
							{{ kw.text }}
						</div>
					</div>
				</div>
				<div v-if="pickerType == 'month'"
					class="picker month">
					<div class="pager">
						<div class="btn prev"
							@click.stop="onPaging('-year')">
							<Icon :icon="$static.iconPrev" />
						</div>
						<div class="text">
							<div class="year"
								@click.stop="pickerType = 'year'">{{ this.pagerYear }}</div>
						</div>
						<div class="btn next"
							@click.stop="onPaging('+year')">
							<Icon :icon="$static.iconNext" />
						</div>
					</div>
					<div class="monthGrid">
						<div class="month"
							v-for="month in calendarMonths"
							:key="month.key"
							:class="month.class"
							@click.stop="onMonthClick(month)">
							{{ month.text }}
						</div>
					</div>
				</div>
				<div v-if="pickerType == 'year'"
					class="picker year">
					<div class="pager">
						<div class="btn prev"
							@click.stop="onPaging('-years')">
							<Icon :icon="$static.iconPrev" />
						</div>
						<div class="text">
							<div class="year">{{ this.pagerYearRange.start }} - {{ this.pagerYearRange.end }}</div>
						</div>
						<div class="btn next"
							@click.stop="onPaging('+years')">
							<Icon :icon="$static.iconNext" />
						</div>
					</div>
					<div class="yearGrid">
						<div class="year"
							v-for="year in calendarYears"
							:key="year.key"
							:class="year.class"
							@click.stop="onYearClick(year)">
							{{ year.text }}
						</div>
					</div>
				</div>
			</Popover>
		</div>
	</div>
</template>

<script>
import Input from '@components/Form/Input'
import Popover from '../Containers/Popover'
import { Day, DateTime } from '@SyoLab/date-time'
import { Back, Forward, CaretSolidDown, ChromeClose, Calendar } from '@icons/appFabric/icons'
import Icon from '@icons/Icon.vue'

function isoGetYear(date) {
	if (!date || typeof date !== 'string') return null
	return date.split('-')[0]
}

function isoGetMonth(date) {
	if (!date || typeof date !== 'string') return null
	let parts = date.split('-')
	if (parts.length < 2) return null
	return `${parts[0]}-${parts[1]}`
}

export default {
	name: 'DatePicker',
	components: { Popover, Icon, DatePickerInput: Input },
	props: {
		value: {
			required: false,
			default: null,
		},
		unit: {
			required: false,
			default: 'day',
			validator: function (value) {
				return ['day', 'month', 'year'].indexOf(value) !== -1
			},
		},
		min: {
			required: false,
			validator: function (value) {
				try {
					new Day(value)
					return true
				} catch (error) {
					return false
				}
			},
		},
		max: {
			required: false,
			validator: function (value) {
				try {
					new Day(value)
					return true
				} catch (error) {
					return false
				}
			},
		},
		allowInput: {
			required: false,
			default: false,
		},
		clearable: {
			required: false,
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			required: false,
			default: '',
		},
		readonly: {
			type: [String, Boolean],
			required: false,
			default: false,
		},
		notification: {
			type: String,
			required: false,
		},
		notificationIsError: {
			type: Boolean,
			required: false,
			default: false,
		},
		autocomplete: {
			type: String,
			required: false,
			default: 'off',
		},
		displayFormat: {
			type: String,
			required: false,
		},
		// toEuDate | toDateTime | toIsoDate | Day (class) | Luxon Format String
		format: {
			type: String,
			required: false,
		},
		options: {
			type: Object,
			required: false,
			default: function () {
				return {}
			},
		},
		placeholder: {
			type: String,
			required: false,
		},
		preselectMonth: { required: false },
		tabindex: { required: false, default: 0 },
		width: { required: false },
	},
	data() {
		return {
			open: false,
			hasFocus: false,
			displayValue: undefined,
			inputValue: null,
			gridMonth: null,
			gridYear: null,
			gridDate: null,
			gridYearRangeMax: null,
			gridYearRangeMin: null,
			pickerType: null,

			date: null,
			now: new Day(),
			mounted: false,
			dateError: false,

			hasInput: false
		}
	},
	static: {
		iconPrev: Back,
		iconNext: Forward,
		iconClose: ChromeClose,
		iconCalendar: Calendar,
	},
	methods: {
		isSelectedDay(d) {
			if (this.date instanceof Day == false) return false
			if (d instanceof Day == false) return false
			return this.date.isEq(d)
		},
		isSelectedMonth(m) {
			if (this.date instanceof Day == false) return false
			if (m instanceof Day == false) return false
			if (m.year != this.date.year) return false
			return m.month == this.date.month
		},
		isSelectedYear(y) {
			if (this.date instanceof Day == false) return false
			if (y instanceof Day == false) return false
			return y.year == this.date.year
		},
		isDisabled(d, unit) {
			if (unit == 'year') {
				if (this.minBoundary && isoGetYear(d.date) < this.minBoundary.year) return true
				if (this.maxBoundary && isoGetYear(d.date) > this.maxBoundary.year) return true
			} else if (unit == 'month') {
				if (this.minBoundary && isoGetMonth(d.date) < this.minBoundary.month) return true
				if (this.maxBoundary && isoGetMonth(d.date) > this.maxBoundary.month) return true
			} else {
				if (this.minBoundary && this.minBoundary.isGt(d)) return true
				if (this.maxBoundary && this.maxBoundary.isLt(d)) return true
			}

			return false
		},
		onDayClick(day) {
			if (day.disabled) return
			this.date = day.d
			this.onSelect()
		},
		onMonthClick(month) {
			if (month.disabled) return
			let m = month.m
			this.gridMonth = m.monthNo
			setTimeout(() => {
				if (this.settings.unit == 'day') {
					// drill down
					this.pickerType = 'day'
				} else {
					// unit month
					this.date = this.getUnitMonthDate({ year: m.year, month: m.monthNo })
					this.onSelect()
				}
			}, 1);

		},
		getUnitMonthDate(date) {
			if (isNaN(this.settings.setDayTo)) {
				let d = new Day({ year: date.year, month: date.month, day: 1 })
				if (this.settings.setDayTo == 'startOf') return d.startOf('month')
				if (this.settings.setDayTo == 'endOf') return d.endOf('month')
				return d
			}
			return new Day({ year: date.year, month: date.month, day: this.settings.setDayTo })
		},
		getUnitYearDate(date) {
			return new Day({ year: date.year, month: this.settings.setMonthTo, day: this.settings.setDayTo })
		},
		onYearClick(year) {

			if (year.disabled) return
			let y = year.y
			this.gridYear = y.year
			if (this.settings.unit == 'day') {
				// drill down
				this.pickerType = 'month'
				return
			}
			if (this.settings.unit == 'month') {
				// drill down
				this.pickerType = 'month'
				// if date is set, move to new year
				if (this.date) {
					try {
						this.date = this.getUnitMonthDate({ year: y.year, month: this.date.monthNo })
						this.onSelect()
					} catch (error) {
						this.date = null
					}
				}
				return
			}

			// unit year:
			this.date = this.getUnitYearDate({
				year: y.year,
			})
			this.onSelect()
		},
		onPaging(direction) {

			if (direction == '+month') {
				let d
				d = new Day({ day: 1, month: this.gridMonth, year: this.gridYear }).plus({ month: 1 })
				this.gridMonth = d.monthNo
				this.gridYear = d.year
			}
			if (direction == '-month') {
				let d
				d = new Day({ day: 1, month: this.gridMonth, year: this.gridYear }).minus({ month: 1 })
				this.gridMonth = d.monthNo
				this.gridYear = d.year
			}
			if (direction == '+year') {
				let d
				d = new Day({ day: 1, month: this.gridMonth, year: this.gridYear }).plus({ year: 1 })
				this.gridMonth = d.monthNo
				this.gridYear = d.year
			}
			if (direction == '-year') {
				let d
				d = new Day({ day: 1, month: this.gridMonth, year: this.gridYear }).minus({ year: 1 })
				this.gridMonth = d.monthNo
				this.gridYear = d.year
			}
			if (direction == '+years') {
				this.gridYearRangeMax = this.gridYearRangeMax.plus({ year: 9 })
				this.gridYearRangeMin = this.gridYearRangeMin.plus({ year: 9 })
			}
			if (direction == '-years') {
				this.gridYearRangeMax = this.gridYearRangeMax.minus({ year: 9 })
				this.gridYearRangeMin = this.gridYearRangeMin.minus({ year: 9 })
			}


		},
		onSelect(event) {

			setTimeout(() => {
				//console.log('onSelect', 'close')
				this.open = false
				this.hasFocus = false
			}, 100)

			let ret
			if (typeof this.settings.onSelect == 'function') {
				this.date = this.settings.onSelect(this.date)
			}
			if (this.date && this.date.invalid) {
				this.date = null
				this.dateError = true;
			} else {
				this.dateError = false;
			}
			ret = this.dayToFormat(this.date, this.settings.format, this.settings.unit)
			if (this.value != ret) {
				this.$emit('input', ret)
				this.$emit('change', ret)
			}
			if (event == 'enter') {
				this.$emit('enter', ret)
			}
		},
		onBlur(event, e) {

			let ret = this.dayToFormat(this.date, this.settings.format, this.settings.unit)
			this.hasFocus = false

			if (this.open && this.hasInput) {
				// try to select
				this.onSelect()
				this.$emit('blur', ret)
				return
			}

			setTimeout(() => {
				// if is still open, no select has occured, it was a blur through a tab for example
				// close input
				if (this.open) {
					this.open = false
					this.hasFocus = false
				}
			}, 200)

			this.$emit('blur', ret)
		},
		onFocus() {
			if (this.datePickerInput) {
				this.inputValue = this.displayValue
				this.hasFocus = true
				this.$emit('focus')
			}
		},
		focus(event) {
			if (this.datePickerInput) {
				this.$refs.input.focus()
			}
			this.toggle(true)
		},
		onInput(value) {

			this.inputValue = value
			if (!value) return
			this.hasInput = true // signal that input has been used	

			let parts = value.split('.')

			let date = { day: null, month: this.gridMonth, year: this.gridYear }
			parts.forEach((value, index) => {
				if (index == 0 && value && value != 0) {
					date.day = value
				}
				if (index == 1 && value && value != 0) {
					date.month = value
					this.gridMonth = value
				}
				if (index == 2 && value && value.length == 4) {
					date.year = value
					this.gridYear = value
				}
			})

			if (date.day) {
				let d = new Day(date)
				if (d.invalid) {
					this.date = null
					this.dateError = true
					return
				}
				this.dateError = false;
				this.date = d;
			}


			//this.$emit('input', event.target.value)
		},
		onInputEnter(event) {
			this.onSelect('enter')
		},
		onEnter(event) {
			this.onSelect('enter')
		},
		onInputClick() {
			this.toggle(true)
		},
		toggle(open) {
			if (this.settings.readonly) return
			if (!this.value && this.settings.preselectMonth) {
				let d = new Day(this.settings.preselectMonth)
				this.gridMonth = d.month
				this.gridYear = d.year
			}
			if (typeof open == 'boolean') {
				this.open = open
			} else {
				this.open = !this.open
			}

			this.hasInput = false // reset input has been used
		},
		outsideClick() {
			this.open = false
		},
		isFunction(fn) {
			return typeof fn == 'function' ? true : false
		},
		onValueChange() {
			if (this.value) {
				// set value
				let d = new Day(this.parse(this.value))
				if (d.invalid) {
					this.clearValue()
					return
				}

				this.date = d
				this.gridYear = this.date.year
				this.gridMonth = this.date.monthNo
				this.gridDay = this.date.day
				// addjust range
				while (this.date.date < this.gridYearRangeMin.date) {
					this.gridYearRangeMin = this.gridYearRangeMin.minus({ year: 9 })
					this.gridYearRangeMax = this.gridYearRangeMax.minus({ year: 9 })
				}
				while (this.date.date > this.gridYearRangeMax.date) {
					this.gridYearRangeMin = this.gridYearRangeMin.plus({ year: 9 })
					this.gridYearRangeMax = this.gridYearRangeMax.plus({ year: 9 })
				}
			} else {
				this.clearValue()
			}
		},
		clearValue() {
			this.date = null
			this.displayValue = ''
			if (this.isFunction(this.settings.min) && this.settings.min.isGt(this.now)) {
				this.gridYear = this.settings.min.year
				this.gridMonth = this.settings.min.monthNo
				this.gridDay = this.settings.min.day
			} else {
				this.gridYear = this.now.year
				this.gridMonth = this.now.monthNo
				this.gridDay = this.now.day
			}
		},
		onClearClick() {

			this.date = null
			this.open = false
			this.$emit('input', null)
			this.$emit('change', null)
		},
		dayToFormat(day, format, unit) {
			if (unit && unit == 'month' && !format) {
				return day.month
			}
			let ret
			switch (format) {
				case 'toEuDate':
					ret = day ? day.toEuDate() : null
					break
				case 'toDateTime':
					ret = day ? day.toDateTime() : null
					break
				case 'toIsoDate':
					if (unit == 'year') {
						ret = day ? String(day.year) : null
						break
					}
					if (unit == 'day') {
						ret = day ? day.date : null
						break
					}
					if (unit == 'month') {
						ret = day ? day.month : null
						break
					}
					if (unit == 'week') {
						ret = day ? day.week : null
						break
					}
					ret = day ? day.date : null
					break
				case 'Day':
					ret = day
					break
				default:
					ret = format ? day.toFormat(format) : day
					break
			}
			return ret
		},
		parse(value) {
			if (!value) return null

			if (this.settings.format) {
				let defaultParser = ['toIsoDate', 'toDateTime', 'toEuDate']
				return defaultParser.includes(this.settings.format)
					? new Day(value)
					: new Day(value, this.settings.format, this.$portal.locale)
			}

			return new Day(value)
		},
		isSame(date) {
			// check null values
			if (!date || !this.date) {
				return this.date == date
			}
			if (this.settings.unit == 'year') {
				return this.date.year == date.year
			}
			if (this.settings.unit == 'month') {
				return this.date.year == date.year && this.date.monthNo == date.monthNo
			}
			return this.date.date == date.date
		},
		updateDisplayValue() {
			if (this.date) {
				if (this.settings.displayFormat) {
					this.displayValue = this.dayToFormat(this.date, this.settings.displayFormat)
				} else if (this.settings.unit == 'month') {
					this.displayValue = this.date.toFormat('MMMM, yyyy')
				} else if (this.settings.unit == 'year') {
					this.displayValue = this.date.toFormat('yyyy')
				} else {
					this.displayValue = this.date
						? this.date.toDateTime().toLocaleString({ year: 'numeric', month: 'long', day: 'numeric' })
						: null
				}
			} else {
				this.displayValue = ''
			}
		},
	},
	computed: {
		settings() {
			let allowInput = this.options.allowInput || this.allowInput
			let notification = this.options.notification || this.notification || this.dateError ? 'Invalid date' : null
			let notificationIsError = this.options.notificationIsError || this.notificationIsError || this.dateError ? true : false
			return {
				label: this.label,
				disabled: !allowInput, // allow typing
				readonly: this.readonly,
				placeholder: this.placeholder,
				notification: notification,
				notificationIsError: notificationIsError,
				autocomplete: this.autocomplete,
				showDateIcon: true, // show calendar icon true || active
				displayFormat: this.displayFormat, // format selected Date to display
				format: this.format, // format returned - value
				clearable: this.clearable, // user can unset date
				setDayTo: 1, // unit month, day = setDayTo || startOf | endOf
				setMonthTo: 1, // unit year, month = setMonthTo
				onSelectFn: null, // function that gets called on selecting a date
				min: this.min,
				max: this.max,
				unit: this.unit,
				preselectMonth: this.preselectMonth,
				fontSize: this.fontSize || null,
				width: this.width || null,
				...this.options,
			}
		},
		pagerMonth() {
			return new Day({ day: 1, month: this.gridMonth, year: this.gridYear }).toFormat('MMMM')
		},
		pagerYear() {
			return new Day({ day: 1, month: this.gridMonth, year: this.gridYear }).toFormat('yyyy')
		},
		pagerYearRange() {
			return {
				start: this.gridYearRangeMin.toFormat('yyyy'),
				end: this.gridYearRangeMax.toFormat('yyyy'),
			}
		},
		calendarHeading() {
			let heading = []

			// first week of month
			let w = new Day({ day: 1, month: this.gridMonth, year: this.gridYear }).startOf('week')
			let weekNo = w.weekNo
			// add days
			while (w.weekNo == weekNo) {
				heading.push({
					text: w.toFormat('ccc'),
					style: {
						['grid-column']: `weekDay${w.weekDay}`,
						['grid-row']: 'header',
					},
				}) // add week
				w = w.plus({ day: 1 })
			}
			return heading
		},
		calendarWeeks() {
			let weeks = []

			// first week of month
			let w = new Day({ day: 1, month: this.gridMonth, year: this.gridYear }).startOf('week')
			let rowNo = 2
			let l = new Day({ day: 1, month: this.gridMonth, year: this.gridYear }).endOf('month')
			let thisWeek = w.week == this.now.week
			// add others
			while (w.isLte(l)) {
				weeks.push({
					text: w.weekNo,
					thisWeek: thisWeek,
					style: {
						['grid-column']: `weekNo`,
						['grid-row']: rowNo,
					},
				}) // add week
				w = w.plus({ week: 1 })
				rowNo += 1
			}
			return weeks
		},
		calendarDays() {
			let days = []
			// first week of month
			let d = new Day({ day: 1, month: this.gridMonth, year: this.gridYear })
			let l = new Day({ day: 1, month: this.gridMonth, year: this.gridYear }).endOf('month')
			// add others
			while (d.isLte(l)) {
				let isDisabled = this.isDisabled(d)
				let thisWeek = d.week == this.now.week
				days.push({
					text: d.day,
					key: d.dayNo,
					style: {
						['grid-column']: `weekDay${d.weekDay}`,
					},
					class: {
						borderBottom: d.weekNo == l.weekNo,
						borderRight: d.weekDay == 7 || d.dayNo == l.dayNo,
						selected: this.isSelectedDay(d),
						disabled: isDisabled,
						today: d.isEq(this.now),
						thisWeek: thisWeek,
						startOfWeek: thisWeek && d.weekDay == 1,
						endOfWeek: thisWeek && d.weekDay == 7,
					},
					disabled: isDisabled,
					d: d,
				}) // add week
				d = d.plus({ day: 1 })
			}
			return days
		},
		calendarMonths() {
			let months = []

			// first month of year
			let m = new Day({ day: 1, month: 1, year: this.gridYear })
			// add others
			while (m.year == this.gridYear) {
				let isDisabled = this.isDisabled(m, 'month')
				months.push({
					text: m.toFormat('MMMM'),
					m: m,
					key: m.monthNo,
					class: {
						selected: this.isSelectedMonth(m),
						disabled: isDisabled,
						today: m.year == this.now.year && m.monthNo == this.now.monthNo,
					},
					disabled: isDisabled,
				}) // add week
				m = m.plus({ month: 1 })
			}
			return months
		},
		calendarYears() {
			let years = []

			let y = this.gridYearRangeMin
			// add others
			while (y.year <= this.gridYearRangeMax.year) {
				let isDisabled = this.isDisabled(y, 'year')
				years.push({
					text: y.toFormat('yyyy'),
					key: y.year,
					y: y,
					class: {
						selected: this.isSelectedYear(y),
						disabled: isDisabled,
						today: y.year == this.now.year,
					},
					disabled: isDisabled,
				}) // add week
				y = y.plus({ year: 1 })
			}
			return years
		},
		minBoundary() {
			return this.settings.min ? new Day(this.settings.min) : null
		},
		maxBoundary() {
			return this.settings.max ? new Day(this.settings.max) : null
		},
		inputClasses() {
			if (this.mounted) {
				return {
					basic:
						this.$el.classList.contains('grid') ||
						this.$el.classList.contains('basic') ||
						this.$el.classList.contains('table'),
					datePickerEnabled: !this.settings.readonly,
					noNotification: this.noNotification,
					focus: this.open,
				}
			}
			return {}
		},
		noNotification() {
			if (this.$el.classList.contains('noNotification')) return true
			if (this.$el.classList.contains('grid')) return true
			if (this.$el.classList.contains('basic')) return true
			if (this.$el.classList.contains('table')) return true
			return false
		},
		dispayCalendarIcon() {
			if (this.settings.icon === true) return true
			if (this.settings.icon == 'active') {
				return this.date ? true : false
			}
			return false
		},
		popoverOffset() {
			if (this.settings.offset) {
				return this.settings.offset
			}

			if (this.settings.refEl) {
				return {}
			}

			if (!this.settings.orientation || this.settings.orientation == 'bottom') {
				// orientation bottom
				if (this.noNotification) {
					return {
						top: 3 // default offset from bottom of notification to top of notification
					}
				} else {
					return {
						top: -17 // default offset from bottom of notification to top of notification
					}
				}
			}

			return {}
		},
		datePickerSettings() {
			return {
				label: this.settings.label,
				readonly: this.settings.readonly || this.settings.datePickerInput,
				notification: this.settings.notification,
				notificationIsError: this.settings.notificationIsError,
				inputmode: 'numeric', // selects keyboard
				fontSize: this.settings.fontSize || null,
			}
		},
		datePickerInput() {
			if (this.settings.displayFormat == 'toEuDate' && this.settings.unit == 'day') {
				return true
			}
			return false
		},
		refEl() {
			if (this.settings.refEl) return this.settings.refEl
			return this.$el

		},
		anchorEl() {
			if (this.settings.anchorEl) return this.settings.anchorEl
			return this.$refs.anchorEl
		},
		style() {
			let width = this.settings.displayFormat == 'toEuDate' ? 135 : 185
			if (this.settings.showDateIcon === false) width -= 42
			if (this.settings.unit == 'year') width = 85
			width = width + 'px'
			if (this.settings.width) width = this.settings.width
			return {
				minWidth: width
			}
		}
	},
	watch: {
		date() {
			this.updateDisplayValue()
		},
		'settings.unit': {
			handler: function (unit) {
				this.updateDisplayValue()
				this.pickerType = unit
				this.onSelect()
			},
		},
		value(newValue) {

			let newDate = newValue ? this.parse(newValue) : null
			if (!newDate || newDate.invalid) {
				this.onValueChange()
				return
			}
			if (this.isSame(newDate)) {
				return
			}
			this.onValueChange()
		},
	},
	created() {
		//set range
		// set to now
		this.gridYearRangeMin = this.now.minus({ year: 9 })
		this.gridYearRangeMax = this.now.plus({ year: 2 })
		this.onValueChange()
		this.updateDisplayValue()

		this.pickerType = this.settings.unit
		if (!this.value && this.settings.preselectMonth) {
			this.gridYear = this.settings.preselectMonth.split('-')[0]
			this.gridMonth = Number(this.settings.preselectMonth.split('-')[1])
			this.gridDay = null
		}

	},
	mounted() {
		this.mounted = true
	},
}
</script>

<style scoped>
.DatePicker {
	display: inline-flex;
	flex-direction: column;
	outline: none;
}

/* .DatePicker:focus {
  background-color: var(--activeHover);
} */


.DatePicker.datatable,
.DatePicker.table {
	justify-content: center;
}

.DatePicker.datatable:deep(input),
.DatePicker.table:deep(input),
.DatePicker.grid:deep(input) {
	background-color: transparent;
	width: unset;
}

.DatePicker.basic .Input::before,
.DatePicker.grid .Input::before,
.DatePicker.table .Input::before {
	display: none;
}

.DatePicker.table .Input::after,
.DatePicker.grid .Input::after,
.DatePicker.table .Input::before {
	display: none;
}

.DatePicker.table>.anchor>.Popover {
	top: 0;
}

.DatePicker.table .Popover::before {
	content: '';
	position: absolute;
	bottom: -6px;
	left: 20px;
	display: inline-block;
	width: 12px;
	height: 12px;
	border-right: 1px solid #dfdfdf;
	border-bottom: 1px solid #dfdfdf;
	transform: rotate(45deg);
	background-color: white;
}

.Popover {
	z-index: 10;
	background-color: white;
}

.DatePicker:deep(.activation) {
	cursor: pointer;
}

.DatePicker.readonly:deep(.activation) {
	cursor: default;
}

.DatePicker.allowInput input {
	cursor: text;
}

.DatePicker.readonly,
.DatePicker.disabled.allowInput input,
.DatePicker.readonly.allowInput input {
	cursor: default;
}

.DatePicker.readonly .clearIcon {
	visibility: hidden;
}

.weeks {
	display: flex;
	flex-direction: column;
}

.weekRow {
	display: flex;
}

.picker {
	display: flex;
	flex-direction: column;
	border: 1px solid #cccccc;
	padding: 15px;
	user-select: none;
}

.DatePicker .anchor {
	position: relative;
	width: 100%;
}

.DatePicker>.anchor>.Popover {
	position: absolute;
	top: 1px;
	left: 0;
	z-index: 5;
	background-color: white;
}

.DatePicker.basic>.anchor>.Popover,
.DatePicker.table>.anchor>.Popover,
.DatePicker.grid>.anchor>.Popover {
	top: 0;
}

.picker.day {
	padding-left: 1px;
}

.iconCalendar {
	margin-left: 2px;
	margin-right: 7px;
}

/* dayGrid */
.dayGrid {
	display: grid;
	grid-template-columns: [weekNo] 20px [weekDay1] 38px [weekDay2] 38px [weekDay3] 38px [weekDay4] 38px [weekDay5] 38px [weekDay6] 38px [weekDay7] 38px;
	grid-template-rows: [header] 18px;
	grid-auto-rows: 38px;
	grid-gap: 0px;
	margin-top: 5px;
	box-sizing: border-box;
}

.dayGrid>div {
	display: flex;
	align-items: center;
	justify-content: center;
}

.dayGrid .day {
	border: 1px solid #dfdfdf;
	margin: 0 -1px -1px 0;
	cursor: pointer;
	position: relative;
}

.dayGrid .day.thisWeek::before {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-top: 1px solid var(--activeBlue);
	border-bottom: 1px solid var(--activeBlue);
}

.dayGrid .day.thisWeek.startOfWeek::before {
	border-left: 1px solid var(--activeBlue);
}

.dayGrid .day.thisWeek.endOfWeek::before {
	border-right: 1px solid var(--activeBlue);
}

.dayGrid .day.selected {
	background-color: #829db6;
}

.dayGrid .day.disabled {
	cursor: not-allowed;
	color: rgb(202, 202, 202);
}

.dayGrid .heading {
	font-size: 10px;
	color: grey;
	border-top: none;
}

.dayGrid .heading:first-child {
	border-left: none;
}

.dayGrid .week {
	font-size: 10px;
	color: grey;
	border-left: none;
}

.dayGrid .day.today::after {
	position: absolute;
	left: 3px;
	top: 3px;
	z-index: 1;
	width: 5px;
	content: '';
	height: 5px;
	border-radius: 50%;
	background-color: var(--activeBlue);
}

/* monthGrid */
.monthGrid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-auto-rows: 38px;
	grid-gap: 0px;
	margin-top: 5px;
	box-sizing: border-box;
	position: relative;
}

.monthGrid .month {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #dfdfdf;
	margin: 0 -1px -1px 0;
	cursor: pointer;
	padding: 0 5px;
	font-size: 13px;
	position: relative;
}

.monthGrid .month.selected {
	background-color: #829db6;
}

.monthGrid .month.disabled {
	cursor: not-allowed;
	color: rgb(202, 202, 202);
}

.monthGrid .month.today::after {
	position: absolute;
	right: 2px;
	top: 2px;
	z-index: 1;
	width: 5px;
	content: '';
	height: 5px;
	border-radius: 50%;
	background-color: #5d5d5d;
}

/* yearGrid */
.yearGrid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-auto-rows: 38px;
	grid-gap: 0px;
	margin-top: 5px;
	box-sizing: border-box;
}

.yearGrid .year {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #dfdfdf;
	margin: 0 -1px -1px 0;
	cursor: pointer;
	padding: 0 10px;
	font-size: 13px;
	position: relative;
}

.yearGrid .year.selected {
	background-color: #829db6;
}

.yearGrid .year.disabled {
	cursor: not-allowed;
	color: rgb(202, 202, 202);
}

.yearGrid .year.today::after {
	position: absolute;
	right: 2px;
	top: 2px;
	z-index: 1;
	width: 5px;
	content: '';
	height: 5px;
	border-radius: 50%;
	background-color: #5d5d5d;
}

.picker.year .pager .year {
	cursor: default;
}

.picker.year .pager .year:hover {
	background-color: white;
}

.pager {
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
}

.picker.day .pager {
	margin-left: 20px;
}

.pager .btn {
	border: 1px solid#dfdfdf;
	border-radius: 3px;
	padding: 4px 8px;
	cursor: pointer;
	width: 36px;
	height: 27px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
}

.pager .text {
	display: inline-flex;
}

.pager .text>div {
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	cursor: pointer;
	padding: 0 5px;
}

.pager .text>div:hover {
	background-color: whitesmoke;
}

.clearIcon {
	font-size: 11px;
	margin: 0 3px;
	cursor: pointer;
}
</style>
