<template>
   <div class="BchProductBilling">
      <div v-if="noData"
         class="noData">noch nichts zum verrechnen..</div>
      <div v-else
         class="body">
         <div v-if="settings.showServiceName"
            class="taskName">{{ service.name }}</div>
         <Textarea v-if="showTextArea"
            :readonly="readonly"
            placeholder="Bemerkungen"
            v-model="service.billingText"
            @input="$emit('update')" />
         <div class="grid"
            :style="gridStyle">
            <div v-for="(record, index) in services"
               :key="index"
               :class="{ notBillable: record.notBillable }"
               class="gridRow">
               <div class="description">{{ record.description }}</div>
               <div class="count">
                  {{ record.count }}
                  Stk.
               </div>
               <div class="price">{{ toCHF(record.price) }}</div>
               <div class="discount">
                  <template v-if="record.discount">
                     {{ record.discount }}%
                  </template>
               </div>
               <div class="value">{{ toValue(record.value) }}</div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import DatePicker from '@components/Form/DatePicker.vue';
import InputTime from '@components/Form/InputTime.vue';
import Switcher from '@components/Form/Switcher.vue';

import Textarea from '@components/Form/Textarea.vue';
import { minutes } from 'src/utils/utils'
export default {
   name: 'BchProductBilling',
   components: {
      DatePicker,
      InputTime,
      Switcher,
      Textarea
   },
   props: {
      service: { required: true, type: Object },
      mode: { required: false, default: 'view' },
      readonly: { required: false, default: false }
   },
   data() {
      return {
      }
   },
   methods: {
      toCHF(value) {
         if (!value) return '--'
         return Number(value).toFixed(2)
      },
      toValue(value) {
         if (!value) return null
         return Number(value).toLocaleString('de-ch', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
         })
      },
   },
   computed: {
      services() {
         return this.service.services
      },
      settings() {
         return this.service.settings
      },
      gridColumns() {
         let columns = [
            { id: 'description', title: 'Bezeichnung', width: '1fr' },
            { id: 'count', title: 'Anzahl', width: 'max-content' },
            { id: 'price', title: 'Preis', width: '85px' },
            { id: 'discount', title: 'Rabatt', width: '45px' },
            { id: 'value', title: 'Betrag', width: 'minmax(85px, max-content)' }
         ]
         return columns
      },
      gridStyle() {
         return {
            gridTemplateColumns: this.gridColumns.map(column => `[${column.id}] ${column.width}`).join(' ')
         }
      },
      noData() {
         return this.services.length == 0
      },
      showTextArea() {
         if (this.mode == 'edit') return true
         return this.service.billingText ? true : false
      }
   },
}
</script>

<style scoped>
.BchProductBilling {
   display: inline-flex;
   flex-direction: column;
   row-gap: 10px;
   padding: 15px 0;
}

.BchProductBilling>.title {
   font-size: 22px;
   font-weight: 300;
}

.Textarea {
   height: 22px;
   padding-left: 0;
}


.body {
   display: flex;
   flex-direction: column;
   row-gap: 5px;
}

.body .taskName {
   font-weight: 500;
   line-height: 18px;
}

.noData {
   color: #a6a6a6;
}

.gridRow {
   display: contents;
}

.grid {
   display: grid;
   width: 100%;
}

.gridRow>* {
   display: flex;
   align-items: flex-start;
   justify-content: flex-start;
   min-height: 24px;
}

.gridRow.notBillable {
   color: #a9a8a8;
}

.grid .description {
   grid-column: description;
}

.grid .count {
   grid-column: count;
   padding: 0 5px;
   display: flex;
   justify-content: center;
}

.grid .price {
   grid-column: price;
   padding: 0 5px;
   display: flex;
   justify-content: flex-end;

}

.grid .discount {
   grid-column: discount;
   padding: 0 5px;
   display: flex;
   justify-content: flex-end;
}

.grid .value {
   grid-column: value;
   padding-left: 5px;
   justify-content: flex-end;
}
</style>